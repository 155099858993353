@import "./icons.scss";

.popupContainer {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 23px;
	background-color: rgba(24, 22, 22, 0.5);
	backdrop-filter: blur(7px);
	z-index: 105;
}

.popup {
	position: relative;
	width: 100%;
	max-width: 600px;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 0px 30px;
	background-color: rgba(27, 28, 36, 1);
	border: 1px solid rgba(242, 201, 76, 1);
	border-radius: 23px;
	overflow: auto;
}

.scrollableContent {
	width: 100%;
	max-height: calc(80vh - 50px);
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
		display: none;
	}

	p {
		margin-bottom: 16px;
	}
}

.button {
	width: 100%;
	height: 55px;
	color: rgb(0, 0, 0);
	font-family: "K-bold", sans-serif;
	font-size: 20px;
	text-transform: uppercase;
	border-radius: 50px;

	&.agree {
		margin-top: 20px;
		margin-bottom: 20px;
		background: linear-gradient(90deg, #F79C4B 0%, #F3C54D 100%);
	}
}

.infoMessage {
	width: 100%;
	padding: 0px 16px;
	border-left: 4px solid #B49F68;
	font-family: "K-regular", sans-serif;
	font-size: 16px;
	line-height: 1.5;
	color: rgba(242, 242, 242, 1);
}

.titleWithIcon {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;

	.iconQuestion {
		width: 20px;
		height: 20px;
		margin-top: 2px;
		background-image: $dQuestion;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.iconAdvice {
		width: 20px;
		height: 20px;
		margin-top: 2px;
		background-image: $dAdvice;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}


	.title {
		font-family: "K-bold", sans-serif;
		font-size: 24px;
		line-height: 1.2;
		color: rgba(242, 242, 242, 1);
	}
}

.mainTitleWithIcon {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;
	margin-top: 20px;

	.icon {
		width: 23px;
		height: 23px;
		margin-top: 2px;
		background-image: $dInfo;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.title {
		font-family: "K-bold", sans-serif;
		font-size: 30px;
		line-height: 1.2;
		color: rgba(242, 242, 242, 1);
	}
}

.regText {
	font-family: "Inter-regular", sans-serif;
	font-size: 14px;
	line-height: 1.5;
	color: rgba(242, 242, 242, 1);
}

.regText b {
	font-family: "Inter-bold", sans-serif;
}

.regText span {
    color: #F3C04D;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8%;
	margin-top: 15px;
    margin-bottom: 15px;
}
