@import "./icons.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
textarea {
  font: inherit;
  border: none;
  outline: none;
  background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[tooltip] {
  display: inline-block;
  position: relative;
}

[tooltip]::before,
[tooltip]::after {
  display: none;
  position: absolute;
}

[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

@font-face {
  font-family: "K-extra-bold";
  src: url("../../public/fonts/K2D-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "K-bold";
  src: url("../../public/fonts/K2D-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "K-semi-bold";
  src: url("../../public/fonts/K2D-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "K-medium";
  src: url("../../public/fonts/K2D-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "K-regular";
  src: url("../../public/fonts/K2D-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "K-thin";
  src: url("../../public/fonts/K2D-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Inter-light";
  src: url("../../public/fonts/Inter_18pt-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter-regular";
  src: url("../../public/fonts/Inter_28pt-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter-bold";
  src: url("../../public/fonts/Inter_18pt-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter-extra-bold";
  src: url("../../public/fonts/Inter_18pt-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Kan-bold";
  src: url("../../public/fonts/KantumruyPro-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Kan-medium";
  src: url("../../public/fonts/KantumruyPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Kan-semibold";
  src: url("../../public/fonts/KantumruyPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "KantumruyPro";
  src: url("../../public/fonts/KantumruyPro-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: "Roboto-bold";
  src: url("../../public/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.uiLoaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(39, 40, 50);
  z-index: 1000;

  .loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 6rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
  }

  .loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem #fff;
    animation-name: pulsIn;
  }

  .loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 #fff;
  }

  @keyframes pulsIn {
    0% {
      box-shadow: inset 0 0 0 1rem #fff;
      opacity: 1;
    }

    50%,
    100% {
      box-shadow: inset 0 0 0 0 #fff;
      opacity: 0;
    }
  }

  @keyframes pulsOut {

    0%,
    50% {
      box-shadow: 0 0 0 0 #fff;
      opacity: 0;
    }

    100% {
      box-shadow: 0 0 0 1rem #fff;
      opacity: 1;
    }
  }
}

//.uiContainer {
//	position: absolute;
//	left: 0px;
//	top: 0px;
//	width: 100%;
//	height: 100vh;
//	background-color: #181616;
//	display: flex;
//	justify-content: center;
//	align-items: flex-start;
//	overflow-y: auto;
//	overflow-x: hidden;
//	padding-top: 62px;
//	padding-bottom: 100px;
//	z-index: 1;
//
//	&::before {
//		content: "";
//		position: absolute;
//		top: 0%;
//		left: 50%;
//		transform: translateX(-50%);
//		width: 100vw;
//		height: 100vh;
//		background: linear-gradient(180deg, rgba(55, 55, 66, 1) 0%, rgba(18, 19, 22, 1) 100%);
//		z-index: 0;
//	}
//
//	&::after {
//		content: "";
//		position: absolute;
//		top: 50%;
//		left: 50%;
//		transform: translateX(-50%) translateY(-50%);
//		width: 200vw;
//		height: 100vh;
//		background-image: $background;
//		background-position: center;
//		background-repeat: no-repeat;
//		background-size: 200%;
//		z-index: 0;
//	}
//}

.uiContainer {
  position: fixed; // изменить с absolute на fixed
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #181616;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 62px;
  padding-bottom: 100px;
  z-index: 1;

  &::before {
    content: "";
    position: fixed; // изменить с absolute на fixed
    top: 0;
    left: 0; // убрать transform
    width: 100%; // использовать 100% вместо 100vw
    height: 100vh;
    background: linear-gradient(180deg, rgba(55, 55, 66, 1) 0%, rgba(18, 19, 22, 1) 100%);
    z-index: 0;
  }

  &::after {
    content: "";
    position: fixed; // изменить с absolute на fixed
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: $background;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; // изменить с 200% на cover
    z-index: 0;
  }
}

.uiBgElement {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 40vh;
  background: linear-gradient(180deg, #0000007e -26.17%, rgba(24, 22, 22, 0) 100%);
  z-index: 0;
}

.uiContainer2 {
  position: relative;
  width: 100%;
  min-height: calc(100vh);
  background-color: #181616;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.uiCardContainer {
  width: 100%;
  max-width: 550px;
  position: relative;
}

.uiButton {
  min-width: 50%;
  background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
  color: rgba(27, 28, 36, 1);
  border-radius: 20px;
  font-family: "Kan-bold", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  height: 46px;
  padding: 0px 30px;
  white-space: nowrap;
}

.uiButton_2 {
  background: none;
  background-color: rgba(255, 255, 255, 0.09);
  color: rgba(255, 255, 255, 1);
}

.swiper {
  overflow: visible !important;
}

.swiper-pagination {
  width: fit-content !important;
  transform: translateY(50px) translateX(-50%) !important;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  left: 0px !important;
  width: 10px !important;
  height: 10px !important;
  background-color: rgba(239, 239, 239, 0.37) !important;
  transform: scale(1) !important;
}

.swiper-pagination-bullet-active {
  background-color: rgba(239, 239, 239, 1) !important;
}

.swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  height: 40px;
  display: block;
  width: 25px;
  height: 25px;
  border-top: 2px solid #f2994a;
  border-left: 2px solid #f2994a;
  transform: rotate(-45deg);
  z-index: 1000;
  cursor: pointer;

  @media (max-width: 560px) {
    display: none;
  }
}

.swiper-button-next {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 40px;
  display: block;
  width: 25px;
  height: 25px;
  border-top: 2px solid #f2994a;
  border-left: 2px solid #f2994a;
  transform: rotate(135deg);
  z-index: 1000;
  cursor: pointer;

  @media (max-width: 560px) {
    display: none;
  }
}

.react-international-phone-input-container {
  position: relative;
  display: flex;
  width: 100% !important;
}

.react-international-phone-input {
  width: 100% !important;
  height: 42px;
  padding-left: 20px;
  border-radius: 0px 50px 50px 0px;
  border-style: solid;
  border-width: 2px 2px 2px 0px;
  border-color: rgba(255, 255, 255, 0.16);
  outline: none;
  background-color: rgb(27, 27, 31);
  text-align: start;
  font-size: 20px;
  font-family: Benzin-Medium, sans-serif;
}

.react-international-phone-country-selector-button {
  position: relative;
  width: 70px !important;
  height: 42px;
  border-radius: 50px 0px 0px 50px;
  border-style: solid;
  border-width: 2px 2px 2px 0px;
  border-color: rgba(255, 255, 255, 0.16);
  outline: none;
  background-color: rgb(27, 27, 31);
  text-align: center;
  font-size: 10px;
}

.react-international-phone-country-selector-button__button-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-international-phone-flag-emoji,
.react-international-phone-country-selector-button__flag-emoji {
  width: 25px;
  height: 25px;
}

.react-international-phone-input-container {
  position: relative !important;
}

.react-international-phone-country-selector-dropdown {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translateY(calc(-100% - 10px));
  width: 100%;
  height: 150px !important;
  overflow-y: scroll;
  backdrop-filter: blur(50px);
  border: 2px solid solid rgb(125, 125, 125) !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
  outline: none !important;
  list-style: none;
}

.react-international-phone-country-selector-dropdown__list-item {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.react-international-phone-input {
  font-family: "K-semi-bold", sans-serif;
  font-size: 20px;
  color: rgb(125, 125, 125);
}
