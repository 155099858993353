@import "./icons.scss";

.cardContainer {
	position: relative;
	width: 100%;
	height: 233px;
	padding: 0px 23px;
	margin-top: 17px;
	margin-bottom: 17px;
}

.cardBackground {
	position: absolute;
	width: calc(100% - 46px);
	height: 100%;
	border-radius: 20px;
	overflow: hidden;
	z-index: 0;

	.bgImage {
		width: 100%;
		height: 100%;
		background-image: url(../../public/images/cardBg.svg);
		background-repeat: repeat;
		background-position: 0 70%;
		background-size: 1000%;
		animation: animatedBackground 50s linear infinite;
    will-change: background-position;

		@keyframes animatedBackground {
			from {
				background-position: 0 70%;
			}
			to {
				background-position: -10000px 70%;
			}
		}
	}
}

.card {
	position: absolute;
	width: calc(100% - 46px);
	height: 233px;
	border-radius: 20px;
	border-width: 1px;
	border-style: solid;
	opacity: 1;
	z-index: 1;
	display: block;

	@starting-style {
		opacity: 0;
	}

	&::before {
		opacity: 1;
	}

	&::after {
		opacity: 1;
	}

	&._hidden {
		opacity: 0;
		z-index: -1;
		transition-timing-function: linear;
		transition-duration: 0.5s;
		transition-behavior: allow-discrete;
		display: none;

		&::before {
			opacity: 0;
		}
		&::after {
			opacity: 0;
		}
	}

	.title {
		position: absolute;
		left: 50%;
		top: 20px;
		transform: translateX(-50%);
		color: rgba(255, 255, 255, 1);
		font-size: 20px;
		text-transform: uppercase;
		font-family: "K-extra-bold", sans-serif;
		white-space: nowrap;
	}

	.energyContainer,
	.planeContainer,
	.propContainer {
		position: absolute;
		left: 50%;
		top: 70px;
		transform: translateX(-50%);
	}

	.energyContainer {
		left: calc(50% - 65px);
		width: 130px;
		height: 77px;
		background-color: rgba(217, 217, 217, 1);
		border-radius: 22px;
		overflow: visible;
		transform: scale(1) translateX(0%);
		animation: toLarge 0.5s linear 0s, resize 2s linear infinite 1s;

		@keyframes toLarge {
			0% {
				transform: scale(0);
			}
			100% {
				transform: scale(1);
			}
		}

		@keyframes resize {
			0% {
				transform: scale(1);
			}
			50% {
				transform: scale(0.9);
			}
			100% {
				transform: scale(1);
			}
		}

		&._toSmall {
			transform: scale(1);
			animation: toSmall 0.5s linear 0s;

			@keyframes toSmall {
				0% {
					transform: scale(1) translateX(0%);
				}
				100% {
					transform: scale(0) translateX(0%);
				}
			}
		}

		&._energyContainer_2 {
			.bg {
				background-image: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%);
			}

			.content {
				&::after {
					background-image: $energy2;
				}
			}
		}

		.bg {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 22px;
			background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			z-index: 0;
		}

		.content {
			position: absolute;
			left: 2px;
			top: 2px;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			background-color: rgb(47, 49, 62);
			border-radius: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 5px;
			font-family: "Kan-bold", sans-serif;
			font-size: 48px;
			color: rgba(255, 255, 255, 1);

			&::after {
				content: "";
				width: 29px;
				height: 49px;
				background-image: $energy;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
	}

	.propContainer {
		top: 56px;
		left: calc(50% - 42.5px);
		border-radius: 22px;
		overflow: visible;
		transform: scale(1) translateX(0%);
		animation: toLarge2 0.5s linear 0s, resize2 2s linear infinite 1s;

		@keyframes toLarge2 {
			0% {
				transform: scale(0) translateX(0%);
			}
			100% {
				transform: scale(1) translateX(0%);
			}
		}

		@keyframes resize2 {
			0% {
				transform: scale(1) translateX(0%);
			}
			50% {
				transform: scale(0.9) translateX(0%);
			}
			100% {
				transform: scale(1) translateX(0%);
			}
		}

		&._toSmall {
			transform: scale(1);
			animation: toSmall 0.5s linear 0s;

			@keyframes toSmall {
				0% {
					transform: scale(1) translateX(0%);
				}
				100% {
					transform: scale(0) translateX(0%);
				}
			}
		}

		.prop {
			svg {
				width: 85px;
				height: 85px;
				animation-name: rotate2;
				animation-duration: 1.5s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				@keyframes rotate2 {
					0% {
						transform: rotateZ(0deg);
					}
					100% {
						transform: rotateZ(360deg);
					}
				}
			}
		}
	}
	.planeContainer {
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
		transform: translateX(0px);
		overflow: hidden;

		.planeWrapper {
			position: absolute;
			top: 63px;
			left: calc(50% - 70px);
			transform: scale(1);
			width: 140px;
			height: 72px;
		}

		&._fly {
			.planeWrapper {
				animation: toCenter 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s,
					movePlane 2s infinite linear 2s;

				@keyframes toCenter {
					0% {
						left: -100%;
					}
					100% {
						left: calc(50% - 70px);
					}
				}

				@keyframes movePlane {
					0% {
						transform: translateY(0px) rotateZ(0deg);
					}
					40% {
						transform: translateY(5px) rotateZ(3deg);
					}
					60% {
						transform: translateY(-5px) rotateZ(3deg);
					}
					100% {
						transform: translateY(0px) rotateZ(0deg);
					}
				}
			}
		}

		&._toLargePlane {
			transform: scale(1);
			animation: 
      // toLargePlane 0.5s linear 0s,
				movePlane 2s infinite linear 0s;

			// @keyframes toLargePlane {
			// 	0% {
			// 		transform: scale(0);
			// 	}
			// 	100% {
			// 		transform: scale(1);
			// 	}
			// }
		}

		&._toSmall {
			.planeWrapper {
				transform: scale(1);
				animation: toSmall 0.5s linear 0s;

				@keyframes toSmall {
					0% {
						transform: scale(1);
					}
					100% {
						transform: scale(0);
					}
				}
			}
		}
	}

	.text {
		position: absolute;
		left: 50%;
		top: 171px;
		transform: translateX(-50%);
		width: 100%;
		color: rgba(219, 219, 219, 1);
		font-family: "K-medium", sans-serif;
		font-size: 14px;
		text-align: center;
		text-transform: uppercase;

		span {
			font-family: "K-extra-bold", sans-serif;
		}
	}
}

.coeffs {
	position: absolute;
	left: 50%;
	top: 158px;
	transform: translateX(-50%);
	width: fit-content;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: linear 0.5s;

	.border {
		position: absolute;
		top: -3px;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 15px;
		z-index: 0;
		transition: linear 0.5s;
	}

	&._c1 {
		transition: linear 0.5s;
		.border {
			background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			z-index: -1;
		}
	}
	&._c2 {
		transition: linear 0.5s;
		.border {
			background-image: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%);
			z-index: -1;
		}
	}
	&._c3 {
		transition: linear 0.5s;
		.border {
			background-image: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);
			z-index: -1;
		}
	}
	&._c4 {
		transition: linear 0.5s;
		.border {
			background-image: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%);
			z-index: -1;
		}
	}

	.coeff {
		position: relative;
		height: 48px;
		display: flex;
		align-items: center;
		gap: 17px;
		background-color: rgba(47, 49, 62, 1);
		border-radius: 15px;
		padding: 0px 15px;
		transition: linear 0.5s;
		z-index: 1;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			top: -50%;
			right: -50%;
			bottom: -50%;
			left: -50%;
			background: linear-gradient(
				to bottom,
				rgba(229, 172, 142, 0),
				rgba(255, 255, 255, 0.5) 50%,
				rgba(229, 172, 142, 0)
			);
			transform: rotateZ(60deg) translate(-5em, 7.5em);
		}

		&:after,
		&:after {
			animation: sheen 2s forwards;
			animation-iteration-count: infinite;
			animation-fill-mode: forwards;
		}

		@keyframes sheen {
			100% {
				transform: rotateZ(60deg) translate(1em, -9em);
			}
		}

		div {
			display: flex;
			flex-direction: column;
			align-items: center;
			white-space: nowrap;

			span {
				&:first-child {
					font-family: "K-bold";
					font-size: 20px;
					color: rgba(255, 255, 255, 1);
					line-height: 23px;
				}
				&:last-child {
					font-family: "K-regular";
					font-size: 12px;
					color: rgba(121, 121, 121, 1);
					line-height: 14px;
				}
			}

			&._c1 {
				span {
					&:first-child {
						background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 20px;
					}
				}
			}
			&._c2 {
				span {
					&:first-child {
						background: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 20px;
					}
				}
			}
			&._c3 {
				span {
					&:first-child {
						background: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 20px;
					}
				}
			}
		}
	}
}

.dotsContainer {
	position: absolute;
	transform: translateY(-50%);
	top: 42%;
	width: 100%;
	height: 20px;
	display: flex;
	justify-content: space-between;

	&._t2 {
		.dotsLeft,
		.dotsRight {
			span {
				background: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%) !important;
			}
		}
	}

	.dotsLeft {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;

		span {
			border-radius: 50%;
			transition: linear 0.5s;

			&:nth-child(1) {
				width: 8px;
				height: 8px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op1;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0s;

				@keyframes op1 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}

			&:nth-child(2) {
				width: 11px;
				height: 11px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op2;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0.1s;

				@keyframes op2 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}

			&:nth-child(3) {
				width: 15px;
				height: 15px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op3;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0.4s;

				@keyframes op3 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}
	}

	.dotsRight {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;

		span {
			border-radius: 50%;
			transition: linear 0.5s;

			&:nth-child(3) {
				width: 8px;
				height: 8px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op1;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0s;

				@keyframes op1 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}

			&:nth-child(2) {
				width: 11px;
				height: 11px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op2;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0.1s;

				@keyframes op2 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}

			&:nth-child(1) {
				width: 15px;
				height: 15px;
				background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				animation-name: op3;
				animation-duration: 1s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
				animation-delay: 0.4s;

				@keyframes op3 {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}
	}
}

.card_1 {
	border-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.53),
		0px 0px 50px 0px rgba(255, 255, 255, 0.7) inset;
}
.card_2 {
	// background-color: rgba(27, 28, 36, 1);
	border-color: rgba(242, 153, 74, 1);
	box-shadow: 0px 0px 50px 0px rgba(242, 178, 75, 0.7) inset,
		0px 0px 20px 0px rgba(242, 178, 75, 0.5);

	// &:before {
	// 	content: "";
	// 	position: absolute;
	// 	left: -1px;
	// 	top: -1px;
	// 	width: calc(100% + 2px);
	// 	height: calc(100% + 2px);
	// 	border-radius: 20px;
	// 	background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
	// 	z-index: -1;
	// 	transition: linear 0s;
	// 	transition-delay: 0.5s;
	// }
}
.card_3 {
	// background-color: rgba(27, 28, 36, 1);
	border-color: rgba(7, 221, 114, 1);
	box-shadow: 0px 0px 50px 0px rgba(9, 216, 127, 0.7) inset, 0px 0px 20px 0px rgba(9, 215, 128, 0.5);

	// &:after {
	// 	content: "";
	// 	position: absolute;
	// 	left: -1px;
	// 	top: -1px;
	// 	width: calc(100% + 2px);
	// 	height: calc(100% + 2px);
	// 	border-radius: 20px;
	// 	background: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);

	// 	z-index: -1;
	// 	transition: linear 0s;
	// 	transition-delay: 0.5s;
	// }
}
.card_5 {
	&:before {
		content: none;
	}
}
.card_4 {
	// background-color: rgba(27, 28, 36, 1);
	border-color: rgba(255, 71, 71, 1);
	box-shadow: 0px 0px 50px 0px rgba(242, 38, 64, 0.7) inset, 0px 0px 20px 0px rgba(242, 39, 64, 0.5);

	// &:before {
	// 	content: "";
	// 	position: absolute;
	// 	left: -1px;
	// 	top: -1px;
	// 	width: calc(100% + 2px);
	// 	height: calc(100% + 2px);
	// 	border-radius: 20px;
	// 	background: linear-gradient(250.77deg, #ff4747 8.76%, #e50539 92.21%);
	// 	z-index: -1;
	// 	// transition: linear 1s;
	// 	// transition-delay: 0.5s;
	// }
}

.winContainer {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;

	span {
		position: absolute;
	}

	.w1 {
		top: 55px;
		left: 56px;
		font-family: "Kan-bold";
		color: rgba(255, 255, 255, 0.5);
		font-size: 32px;
		filter: blur(2px);
		rotate: -10deg;

		&._visibleW1 {
			animation: visibleW1 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s,
				moveW1 3s infinite linear 0.5s;

			@keyframes visibleW1 {
				0% {
					left: -100%;
				}
				100% {
					left: 56px;
				}
			}
		}

		&._hiddenW1 {
			animation: hiddenW1 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

			@keyframes hiddenW1 {
				0% {
					left: 56px;
				}
				100% {
					left: -100%;
				}
			}
		}

		@keyframes moveW1 {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(5px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}
	.w2 {
		top: 57px;
		right: 48px;
		font-family: "Kan-bold";
		color: rgba(255, 255, 255, 1);
		font-size: 24px;
		filter: blur(2px);
		rotate: 7deg;

		&._visibleW2 {
			animation: visibleW2 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s,
				moveW2 3s infinite linear 0.3s;

			@keyframes visibleW2 {
				0% {
					right: -100%;
				}
				100% {
					right: 48px;
				}
			}
		}
		&._hiddenW2 {
			animation: hiddenW2 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

			@keyframes hiddenW2 {
				0% {
					right: 48px;
				}
				100% {
					right: -100%;
				}
			}
		}

		@keyframes moveW2 {
			0% {
				transform: translateY(0px);
			}
			50% {
				transform: translateY(-5px);
			}
			100% {
				transform: translateY(0px);
			}
		}
	}
	.w3 {
		bottom: 38px;
		left: 12px;
		font-family: "Kan-bold";
		color: rgba(255, 255, 255, 1);
		font-size: 48px;
		filter: blur(4px);
		rotate: 10deg;

		&._visibleW3 {
			animation: visibleW3 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55) moveW2 2s infinite linear
				0.6s;

			@keyframes visibleW3 {
				0% {
					left: -100%;
				}
				100% {
					left: 12px;
				}
			}
		}
		&._hiddenW3 {
			animation: hiddenW3 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;

			@keyframes hiddenW3 {
				0% {
					left: 12px;
				}
				100% {
					left: -100%;
				}
			}
		}
	}
	.w4 {
		bottom: 59px;
		right: 43px;
		font-family: "Kan-bold";
		color: rgba(255, 255, 255, 0.5);
		font-size: 32px;
		filter: blur(2px);
		rotate: -3deg;

		&._visibleW4 {
			animation: visibleW2 1.1s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s,
				moveW1 4s infinite linear 0.5s;

			@keyframes visibleW2 {
				0% {
					right: -100%;
				}
				100% {
					right: 43px;
				}
			}
		}
		&._visibleW4 {
			animation: hiddenW4 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
			@keyframes hiddenW4 {
				0% {
					right: -100%;
				}
				100% {
					right: 43px;
				}
			}
		}
	}
}
