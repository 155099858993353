$arrowBack: url(../../public/images/icons/arrowBack.svg);
$arrowShowMore: url(../../public/images/icons/arrowShowMore.svg);
$arrowTop: url(../../public/images/icons/arrowTop.svg);
$arrows: url(../../public/images/icons/arrows.svg);
$background: url(../../public/images/icons/background.svg);
$bgDownloading: url(../../public/images/icons/bgDownloading.svg);
$bgPlane: url(../../public/images/icons/bgPlane.svg);
$bgStars: url(../../public/images/icons/bgStars.svg);
$closePopup: url(../../public/images/icons/closePopup.svg);
//energy replace
$energy: url(../../public/images/icons/energy.svg);
$energy2: url(../../public/images/icons/energy2.svg);
$finger: url(../../public/images/icons/finger.svg);
$hist1: url(../../public/images/icons/hist1.svg);
$hist2: url(../../public/images/icons/hist2.svg);
$historyBackground: url(../../public/images/icons/historyBackground.svg);
$iconArrow: url(../../public/images/icons/users.svg);
$iconClose: url(../../public/images/icons/users.svg);
$iconInfo: url(../../public/images/icons/iconInfo.svg);
$language: url(../../public/images/icons/language.svg);
$logo: url(../../public/images/icons/logo.svg);
$logoForInstall: url(../../public/images/icons/logoForInstall.svg);
$play1: url(../../public/images/icons/play1.svg);
$play2: url(../../public/images/icons/play2.svg);
$playVideo: url(../../public/images/icons/playVideo.svg);
$proc: url(../../public/images/icons/proc.svg);
$ratingStar: url(../../public/images/icons/ratingStar.svg);
$size: url(../../public/images/icons/size.svg);
$telegram1: url(../../public/images/icons/telegram1.svg);
$telegram2: url(../../public/images/icons/telegram2.svg);
$tg: url(../../public/images/icons/users.svg);
$tgBtn: url(../../public/images/icons/tgBtn.svg);
$users: url(../../public/images/icons/users.svg);
$wallet: url(../../public/images/icons/wallet.svg);
$wtf1: url(../../public/images/icons/wtf1.svg);
$wtf2: url(../../public/images/icons/wtf2.svg);

$dQuestion: url(../../public/images/icons/dQuestion.svg);
$dInfo: url(../../public/images/icons/dInfo.svg);
$dAdvice: url(../../public/images/icons/dAdvice.svg);
