@import "./icons.scss";

.main {
	position: relative;
	max-width: 560px;
	width: 100%;
	min-height: fit-content;
	display: flex;
	flex-direction: column;
	margin-bottom: 120px;
	z-index: 1;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	.history {
		width: 100%;
		padding: 0px 23px;

		.coefficientsContainer {
			position: relative;
			width: calc(100% + 24px);
			height: 38px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5px;
			padding: 0px 5px;
			background-color: rgba(47, 49, 62, 1);
			border-radius: 17px 0px 0px 17px;
			overflow: hidden;

			// &::before {
			// 	content: "";
			// 	position: absolute;
			// 	right: 0px;
			// 	top: 0px;
			// 	width: 60%;
			// 	height: 100%;
			// 	background-image: linear-gradient(270deg, rgba(47, 47, 47, 1) 5%, rgba(51, 51, 51, 0) 100%);
			// 	z-index: 1;
			// }

			.coeff {
				height: 26px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #1f1f1f;
				font-family: "Inter-extra-bold", sans-serif;
				font-size: 14px;
				border-radius: 17px;
				transition: transform 0.3s ease-in-out;

				&._blue {
					min-width: 56px;
					color: rgba(0, 176, 255, 1);
				}
				&._purple {
					min-width: 65px;
					color: rgba(124, 77, 255, 1);
				}
				&._pink {
					min-width: 65px;
					color: rgba(192, 23, 180, 1);
				}
				&._gold {
					min-width: 75px;
					color: rgba(255, 187, 0, 1);
				}
				&._gold2 {
					min-width: 100px;
					color: rgba(255, 187, 0, 1);
				}
				&._firstBlue {
					min-width: 56px;
					background-color: rgba(0, 176, 255, 1);
					color: rgba(39, 38, 46, 1);
					transition: transform 0.3s ease-in-out;
				}
				&._firstPurple {
					min-width: 65px;
					background-color: rgba(124, 77, 255, 1);
					color: rgba(39, 38, 46, 1);
					transition: transform 0.3s ease-in-out;
				}
				&._firstPink {
					min-width: 65px;
					background-color: rgba(192, 23, 180, 1);
					color: rgba(39, 38, 46, 1);
					transition: transform 0.3s ease-in-out;
				}
				&._firstGold {
					min-width: 75px;
					background-color: rgba(255, 187, 0, 1);
					color: rgba(39, 38, 46, 1);
					transition: transform 0.3s ease-in-out;
				}
				&._firstGold2 {
					min-width: 100px;
					background-color: rgba(255, 187, 0, 1);
					color: rgba(39, 38, 46, 1);
					transition: transform 0.3s ease-in-out;
				}
			}

			.currentCoeff {
				position: absolute;
				transform: translateX(calc(-100% - 5px));
			}
		}

		.stats {
			position: relative;
			width: 100%;
			height: 52px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;
			padding: 0px 10px;
			background-color: #1c1c1c;
			border-radius: 17px;
			background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
			background-repeat: no-repeat;
			background-position: center;

			&:before {
				content: "";
				position: absolute;
				left: 0;
				display: inline-block;
				width: 100%;
				height: 100%;
				background-image: $historyBackground;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: 50% 70%;
				z-index: 0;
			}

			.sec1 {
				position: relative;
				display: flex;
				align-items: center;
				gap: 11px;
				z-index: 1;

				&._anim {
					&::before {
						animation-name: move;
						animation-duration: 1s;
						animation-timing-function: linear;
						animation-iteration-count: infinite;
					}

					@keyframes move {
						0% {
							transform: translateY(0px);
						}

						50% {
							transform: translateY(-22px);
						}

						100% {
							transform: translateY(0px);
						}
					}
				}

				&::before {
					content: "";
					display: inline-block;
					width: 22px;
					height: 31px;
					background-image: $arrowTop;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}

				.title {
					display: flex;
					flex-direction: column;
					span {
						color: rgba(35, 35, 42, 1);
						line-height: 100%;
            text-transform: uppercase;

						&:first-child {
							font-family: "K-extra-bold";
							font-size: 14px;
						}

						&:last-child {
							font-family: "K-thin";
							font-size: 18px;
						}
					}
				}
			}

			.sec2 {
				position: relative;
				display: flex;
				align-items: center;
				gap: 5px;
				transition: linear 0.5s;
				z-index: 1;

				&._rotate {
					transform: rotateX(90deg);
					opacity: 0;
				}

				&::before {
					content: "";
					display: inline-block;
					width: 24px;
					height: 24px;
					background-color: rgba(35, 35, 42, 1);
					border-radius: 7px;
					background-image: $users;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 65%;
				}

				.title {
					display: flex;
					flex-direction: column;
					span {
						color: rgba(52, 52, 52, 1);
						line-height: 100%;

						&:first-child {
							font-family: "K-medium";
							font-size: 14px;
						}

						&:last-child {
							font-family: "K-thin";
							font-size: 12px;
						}
					}
				}
			}

			.sec3 {
				position: relative;
				display: flex;
				align-items: center;
				gap: 5px;
				transition: linear 0.5s;
				z-index: 1;

				&._rotate {
					transform: rotateX(90deg);
					opacity: 0;
				}

				&::before {
					content: "";
					display: inline-block;
					width: 24px;
					height: 24px;
					background-color: rgba(35, 35, 42, 1);
					border-radius: 7px;
					background-image: $wallet;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 65%;
				}

				.title {
					display: flex;
					flex-direction: column;
					span {
						color: rgba(52, 52, 52, 1);
						line-height: 100%;

						&:first-child {
							font-family: "K-medium";
							font-size: 14px;
						}

						&:last-child {
							font-family: "K-thin";
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

.actions {
	position: relative;
	width: 100%;
	height: 43px;
	margin-bottom: 17px;

	.buttons {
		position: absolute;
		left: 50%;
		transform: translateX(-50%) // rotateX(180deg)
;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 13px;
		z-index: 0;
		opacity: 0;
		transition: linear 0.8s;
		filter: blur(20px);

		&._active {
			transform: translateX(-50%) // rotateX(0deg)
;
			opacity: 1;
			filter: blur(0px);
			z-index: 1;
		}

		.b {
			position: relative;
			min-width: 140px;
			max-width: 188px;
			padding: 0px 20px;
			height: 43px;
			border-radius: 15px;

			span {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: "Kan-bold", sans-serif;
				font-size: 16px;
        text-transform: uppercase;
				white-space: nowrap;
				color: rgba(255, 255, 255, 1);
				z-index: 1;

				svg {
					margin-left: 2px;
					margin-right: 4px;
				}
			}

			&:before {
				content: "";
				display: inline-block;
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 15px;
				top: 3px;
				left: 0;
				z-index: -1;
			}

			&:after {
				content: "";
				position: relative;
				z-index: 0;
			}

			&._flicker {
				opacity: 1;
				animation: flicker 2s linear infinite;

				@keyframes flicker {
					0% {
						opacity: 1;
					}
					50% {
						opacity: 0.5;
					}
					100% {
						opacity: 1;
					}
				}
			}

			&._1 {
				background-image: linear-gradient(360deg, #ff7a05 0%, #ffc72c 97.67%);

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					background-image: $bgStars;
					background-repeat: no-repeat;
					background-position: 50% 38%;
					background-size: cover;
				}

				&::before {
					background-color: rgba(181, 84, 0, 1);
				}
			}

			&._2 {
				background-image: linear-gradient(360deg, #c2162d 0%, #ff3953 97.67%);
				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					background-image: $bgPlane;
					background-repeat: no-repeat;
					background-position: 50% 60%;
					background-size: cover;
				}

				&::before {
					background-color: rgba(137, 14, 30, 1);
				}
			}

			&._3 {
				background-image: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);
				box-shadow: 0px 0px 10px 0px rgba(102, 255, 143, 1) inset;
				box-shadow: 0px 0px 15px 0px rgba(58, 255, 170, 1);

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					background-image: $bgPlane;
					background-repeat: no-repeat;
					background-position: 50% 60%;
					background-size: cover;
				}

				&::before {
					border-bottom: 3px solid rgba(0, 114, 76, 1) !important;
				}
			}

			&._21 {
				&:before {
					box-shadow: 0px 0px 10px 0px rgba(255, 102, 122, 1) inset;
					box-shadow: 0px 0px 15px 0px rgba(255, 58, 62, 1);
				}
			}
			&._4 {
				background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
				&:before {
          background-color: rgba(150, 108, 42, 1);
					box-shadow: 0px 0px 10px 0px rgba(255, 226, 157, 1) inset;
					box-shadow: 0px 0px 15px 0px rgba(243, 183, 76, 1);
				}
			}
		}
	}
}

.footer {
	// flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	.bottom {
		width: 100%;
		height: 95px;
		padding: 0px 23px;

		.progressLineContainer {
			width: 100%;
			padding: 12px;
			background-color: rgba(47, 49, 62, 1);
			border-radius: 15px;

			.progress {
				width: 100%;
				height: 12px;
				background-color: rgba(0, 0, 0, 0.25);
				border-radius: 12px;
				overflow: hidden;

				.line {
					width: 0%;
					height: 100%;
					border-radius: 12px;

					&._red {
						background: linear-gradient(48.09deg, #bc002d -18.68%, #dc3a3a 125.61%);
					}
					&._gold {
						background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
					}
					&._green {
						background: linear-gradient(50.69deg, #1c9b48 0%, #38e85f 98.76%);
					}
				}
			}

			.chanceContainer {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 8px;
				gap: 10px;

				.banner {
					width: 200px;
					height: 44px;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 2px;
					background-color: rgba(255, 255, 255, 0.09);
					border-radius: 50px;

					&:after {
						content: "";
						display: inline-block;
						width: 13px;
						height: 13px;
						background-image: $proc;
						background-repeat: no-repeat;
						background-position: center;
						background-size: contain;
					}

					span {
						font-family: "Kan-bold", sans-serif;
						font-size: 14px;
						text-transform: uppercase;
						color: rgba(255, 255, 255, 1);
					}
				}

				.proc {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-end;

					span {
						line-height: 100%;
						&:first-child {
							font-family: "K-thin", sans-serif;
							font-size: 32px;
							color: rgba(255, 255, 255, 1);
						}
						&:last-child {
							font-family: "K-thin", sans-serif;
							font-size: 12px;
							color: rgba(255, 255, 255, 0.31);
						}
					}
				}
			}
		}
	}
}
