@import "./icons.scss";

.android {
	&Container {
		position: relative;
		width: 100%;
		height: fit-content;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		min-height: 100vh;
		background: linear-gradient(180deg, #373742 0%, #121316 13.03%);

		&._hidden {
			height: 0px;
			overflow: hidden;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0px;
			top: 0%;
			transform: translateY(-50%);
			width: 100%;
			height: 100vh;
			background-image: $background;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			z-index: 2;
		}
		&:after {
			content: "";
			position: absolute;
			left: 0px;
			top: 0%;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, #000000 -150%, rgba(24, 22, 22, 0) 100%);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			z-index: 1;
		}
	}

	&Content {
		position: relative;
		max-width: 530px;
		min-height: 100vh;
		width: 100%;
		padding: 0px 20px 20px 20px;
		z-index: 100;

		.logoSection {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;

			.logo {
				width: 232px;
				height: 47px;
				background-image: $logoForInstall;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}

		.infoSection {
			max-width: 475px;
			width: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.info {
				position: relative;
				width: 100%;
				height: 61px;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				background-color: rgba(255, 255, 255, 0.03);
				border-radius: 20px;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border-radius: 20px;
					padding: 2px;
					background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
					-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
					-webkit-mask-composite: destination-out;
					mask-composite: exclude;
					pointer-events: none;
				}

				& > div {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					gap: 5px;

					span {
						&:first-child {
							font-family: "Kan-medium", sans-serif;
							font-size: 17px;
							color: rgba(255, 255, 255, 1);
						}
						&:last-child {
							font-family: "Kan-medium", sans-serif;
							font-size: 12px;
							color: rgba(109, 109, 109, 1);
						}
					}
				}

				.size {
					position: relative;

					&::before,
					&::after {
						content: "";
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 1px;
						height: 70%;
						background-color: rgba(200, 200, 200, 0.19);
					}

					&::before {
						left: 0px;
					}
					&::after {
						right: 0px;
					}

					span {
						&:first-child {
							width: 20px;
							height: 20px;
							&::before {
								content: "";
								display: block;
								width: 20px;
								height: 20px;
								background-image: $size;
								background-repeat: no-repeat;
								background-position: center;
								background-size: contain;
							}
						}
					}
				}

				.reviews {
					span {
						&:first-child {
							position: relative;
							width: 20px;
							height: 20px;

							&::after {
								content: "";
								position: absolute;
								right: -19px;
								top: 50%;
								transform: translateY(-50%);
								display: block;
								width: 14px;
								height: 14px;
								background-image: $ratingStar;
								background-repeat: no-repeat;
								background-position: center;
								background-size: contain;
							}
						}
					}
				}
			}
		}

		.downloadSection {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;

			.button {
				position: relative;
				width: 218px;
				height: 50px;
				border-radius: 20px;
				overflow: hidden;
				cursor: pointer;

				span {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
					font-family: "Kan-bold", sans-serif;
					font-size: 20px;
					text-transform: uppercase;
					color: rgba(255, 255, 255, 1);
					z-index: 3;
				}
			}

			.buttonDownload {
				background: linear-gradient(360deg, #ff7a05 0%, #ffc72c 97.67%);
				border-bottom: 4px solid rgba(181, 84, 0, 1);

				&::before {
					content: "";
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					background-image: $bgStars;
					background-size: 75%;
					background-position: 50%;
				}
			}

			.buttonInstall {
				background: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);
				border-bottom: 4px solid rgba(4, 124, 66, 1);

				&::before {
					content: "";
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					background-image: $bgStars;
					background-size: 75%;
					background-position: 50%;
				}

				span {
					// &:before {
					// 	content: "";
					// 	position: relative;
					// 	top: 4px;
					// 	left: -2px;
					// 	display: inline-block;
					// 	width: 22px;
					// 	height: 22px;
					// 	background-image: $instal;
					// 	background-repeat: no-repeat;
					// 	background-position: center;
					// 	background-size: contain;
					// }
				}
			}
			.buttonInstalling {
				background-color: rgba(4, 124, 66, 1);
				border-bottom: 4px solid rgba(4, 124, 66, 1);

				.installing {
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					border-radius: 20px;
					background: linear-gradient(250.77deg, #07dd72 8.76%, #0ad18c 92.21%);

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-image: $bgDownloading;
						background-position: -40px;
						background-repeat: repeat-x;
						animation: ding linear infinite 60s 0s;

						@keyframes ding {
							0% {
								background-position: -40px;
							}
							100% {
								background-position: 100%;
							}
						}
					}
				}
			}

			.buttonDownloading {
				background-color: rgba(87, 42, 0, 1);
				border-bottom: 4px solid rgba(181, 84, 0, 1);

				.downloading {
					position: absolute;
					left: 0px;
					top: 0px;
					width: 0%;
					height: 100%;
					border-radius: 20px;
					background: linear-gradient(360deg, #ff7a05 0%, #ffc72c 97.67%);
					// transition: linear 0.5s 0s;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-image: $bgDownloading;
						background-position: -40px;
						background-repeat: repeat-x;
						animation: ding linear infinite 60s 0s;

						@keyframes ding {
							0% {
								background-position: -40px;
							}
							100% {
								background-position: 100%;
							}
						}
					}
				}
			}
		}

		.sliderSection {
			position: relative;
			width: 100%;
			height: 350px;
			margin-top: 25px;

			.slider {
				position: absolute;
				left: -20px;
				top: 0px;
				width: calc(100% + 40px);
				display: flex;
				gap: 20px;
				overflow-y: hidden;
				overflow-x: auto;
				padding: 15px 0px;

				&::-webkit-scrollbar {
					width: 0;
					height: 0;
				}

				.slide {
					width: 180px;
					height: 320px;

					&:first-child {
						margin-left: 20px;
					}
					&:last-child {
						margin-right: 20px;
					}
				}
			}
		}

		.descriptionSection {
			width: 100%;
			height: fit-content;
			padding: 25px;
			background-color: rgba(47, 49, 62, 0.64);
			border-radius: 15px;
			margin-top: 20px;

			.title {
				display: inline-block;
				font-family: "Kan-medium", sans-serif;
				font-size: 22px;
				color: rgba(255, 255, 255, 1);
				margin-bottom: 15px;
			}

			.text {
				display: inline-block;
				font-family: "Inter-regular", sans-serif;
				font-size: 14px;
				color: rgba(157, 157, 157, 1);
			}
		}

		.ratingSection {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			gap: 25px;
			margin-top: 20px;

			.rating {
				display: flex;
				flex-direction: column;
				align-items: center;

				.num {
					display: inline-block;
					font-family: "Inter-regular", sans-serif;
					font-size: 54px;
					line-height: 100%;
					color: rgba(255, 255, 255, 1);
					margin-bottom: 3px;
				}

				.stars {
					width: 100%;
					height: 15px;
					background: $ratingStar 0 0/15px no-repeat, $ratingStar 16px 0/15px no-repeat,
						$ratingStar 32px 0/15px no-repeat, $ratingStar 48px 0/15px no-repeat,
						$ratingStar 64px 0/15px no-repeat;
				}

				.reviews {
					display: inline-block;
					font-family: "Inter-regular", sans-serif;
					font-size: 12px;
					line-height: 150%;
					color: rgba(109, 109, 109, 1);
				}
			}

			.lines {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				flex-grow: 1;

				.lineBg {
					width: 100%;
					height: 12px;
					background-color: rgba(175, 175, 175, 0.17);
					border-radius: 12px;

					.line {
						position: relative;
						height: 100%;
						border-radius: 12px;
						background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);

						&::before {
							content: "5";
							transform: translateX(-15px) translateY(-4px);
							display: inline-block;
							font-family: "Inter-regular", sans-serif;
							font-size: 12px;
							color: rgba(109, 109, 109, 1);
						}

						&::after {
							content: "";
							position: absolute;
							left: 0px;
							top: 0px;
							width: 100%;
							height: 100%;
							border-radius: 12px;
							background-image: $bgDownloading;
							background-position: 50%;
						}
					}

					&:nth-child(1) {
						.line {
							width: 93%;

							&::before {
								content: "5";
							}
						}
					}
					&:nth-child(2) {
						.line {
							width: 40%;

							&::before {
								content: "4";
							}
						}
					}
					&:nth-child(3) {
						.line {
							width: 20%;

							&::before {
								content: "3";
							}
						}
					}
					&:nth-child(4) {
						.line {
							width: 8%;
							&::before {
								content: "2";
							}
						}
					}
					&:nth-child(5) {
						.line {
							width: 5%;
							&::before {
								content: "1";
							}
						}
					}
				}
			}
		}

		.commentsSection {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;
			margin-top: 20px;

			.comment {
				width: 100%;
				height: fit-content;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding: 12px;
				background-color: rgba(37, 37, 37, 1);
				border-radius: 4px;

				.header {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.user {
						display: flex;
						align-items: center;
						gap: 5px;

						.avatar {
							width: 32px;
							height: 32px;
							border-radius: 50%;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
							background-color: white;
						}

						.info {
							display: flex;
							flex-direction: column;
							align-items: flex-start;

							.name {
								font-family: "Inter-regular", sans-serif;
								font-size: 14px;
								color: rgba(255, 255, 255, 1);
							}
							.date {
								font-family: "Inter-regular", sans-serif;
								font-size: 10px;
								color: rgba(109, 109, 109, 1);
							}
						}
					}

					.rating {
						width: 75px;
						height: 18px;
						background-image: $ratingStar;
						background-repeat: repeat-x;
					}
				}

				.body {
					.comment {
						padding: 0px;
						margin-top: 12px;
						color: rgba(141, 141, 141, 1);
						font-family: "Inter-regular", sans-serif;
						font-size: 14px;
					}
				}
			}
		}
	}
}

.ios {
	&Content {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		transform: translateX(110%);
		display: flex;
		justify-content: center;
		padding-top: 70px;
		padding-bottom: 30px;
		background: linear-gradient(180deg, #373742 0%, #121316 13.03%);
		overflow-y: auto;
		z-index: 100;
		transition: linear 0.3s;

		&._active {
			transform: translateX(0%);
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			width: 100%;
			background: linear-gradient(0deg, #000000 -26.17%, rgba(24, 22, 22, 0) 100%);
		}

		.head {
			position: fixed;
			left: 0px;
			top: 0px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 18px 23px;
			z-index: 100;

			.logo {
				position: relative;
				width: 123px;
				height: 26px;
				background-image: $logo;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;

				&:before {
					content: "";
					position: absolute;
					top: -80px;
					transform: translateX(30px);
					display: inline-block;
					width: 72px;
					height: 72px;
					background-image: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
					border-radius: 50%;
					filter: blur(30px);
				}
			}

			.languages {
				width: 56px;
				height: 26px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 3px;
				background-color: rgba(47, 49, 62, 1);
				border-radius: 5px;

				&:before {
					content: "";
					display: inline-block;
					width: 11px;
					height: 12px;
					background-image: $language;
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
				}

				.languageContainer {
					position: relative;
					width: 30px;
					height: 100%;
					display: flex;
					overflow: hidden;
				}

				.language {
					position: absolute;
					left: 0px;
					color: rgba(255, 255, 255, 1);
					font-size: 14px;
					font-family: "Inter-bold", sans-serif;
					opacity: 0;
					transition: linear 0.5s;

					&:first-child {
						transform: translateY(-24px);
					}
					&:last-child {
						transform: translateY(24px);
					}

					&._active {
						transform: translateY(4.5px);
						opacity: 1;
					}
				}
			}
		}

		.body {
			position: relative;
			max-width: 550px;
			width: 100%;
			height: fit-content;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0px 20px;
			z-index: 100;

			.backContainer {
				width: 100%;
        padding: 0px 0px 30px 0px;

				.back {
					font-family: "Kan-medium", sans-serif;
					font-size: 16px;
					color: rgba(255, 255, 255, 1);
					&:before {
						content: "";
						display: inline-block;
						width: 13px;
						height: 10px;
						background-image: $arrowBack;
						margin-right: 3px;
					}
				}
			}

			.title {
				font-family: "K-semi-bold", sans-serif;
				font-size: 20px;
				text-transform: uppercase;
				text-align: center;
				color: rgba(255, 255, 255, 1);
				margin-bottom: 30px;
			}

			.step {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				margin-top: 10px;

				.num {
					min-width: 65px;
					height: 65px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 10px;
					font-family: "K-bold", sans-serif;
					font-size: 32px;
					background: linear-gradient(90deg, #f2994a 0%, #f2c94c 100%);
					margin-right: 10px;
				}

				&Title {
					flex-grow: 1;
					display: flex;
					justify-content: center;
					align-items: center;
					font-family: "K-regular", sans-serif;
					font-size: 20px;
					line-height: 100%;
					text-align: center;
					color: rgba(255, 255, 255, 1);
					border-radius: 10px;
					background-color: rgba(39, 40, 50, 1);
					padding: 0px 10px;
				}
			}

			.imageContainer {
				width: 100%;
				aspect-ratio: 1/1;
				background-color: rgba(97, 97, 97, 1);
				border-radius: 10px;
				margin-top: 10px;
			}
		}
	}
}
